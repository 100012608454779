import React from "react";
import { Link } from "react-router-dom";


export default function Notfound() {
    return (
        <div>
            <Link to="/">
                <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725567833/notfound_mrmvmf.png" alt="not-found-inversiones-dubai" className="nfimage" />
            </Link>
        </div>
    )
}