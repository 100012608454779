import React from "react";

import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Realstate() {
    return (
        <div className="cnt_advice">
            <Helmet>
                <title>Invierte en el Mercado Inmobiliario de Dubai - Inversiones Dubai</title>
                <meta name="description" content="Invierte en bienes raíces en Dubái con la asesoria de Diego Carranza. Te brinda opciones, precios, metraje cuadrado y muchas cosas más." />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>
            <div className="cnt_first-background">
                <p className="background-title size-title"><i className="color">NUESTRA</i> MISIÓN</p>
                <p className="background-title">Ayudamos a expertos, empresas y gente del común a globalizar su mercado en Dubái</p>
            </div>
            <div className="cnt_advice-title">
                <p className="subtitle-advice">No esperes más. Nunca fue tan facil</p>
                <h1 className="title-advice">INVIERTE EN <i className="color">BIENES RAÍCES</i> Y OBTÉN TU PRIMER <i className="color">INMUEBLE EN DUBÁI</i></h1>
            </div>
            <div className="cnt_first-background-image">
            </div>
            <div className="advice-card-information">
                <p className="advice-card-text">¿Estás planeando invertir en bienes raíces en Dubái? Desde Inversiones Dubái no solo te ayudaré a encontrar la propiedad perfecta, sino que también te asesoraré sobre el proceso de compra, el papeleo y todo lo que necesitas saber para hacer de tu compra un éxito.</p>
                <h2 className="advice-card-title color">¿BENEFICIOS DE COMPRAR PROPIEDADES SOBRE PLANO VS PROPIEDADES PREPARADAS EN DUBÁI?</h2>
                <p className="advice-card-text">Cuando se trata de bienes raises listas para la venta en Dubái, los compradores se benefician al saber exactamente cómo es la propiedad, en contraste con las propiedades sobre plano.</p>
                <p className="advice-card-text">Además, los inversores que compran un inmueble o propiedad en dubai lista, pueden comenzar a <Link to="/" className="links-decoration">generar ingresos</Link> de alquiler de inmediato al arrendar la unidad. Los compradores, también pueden obtener una mayor relación préstamo-valor (LTV) para propiedades listas, lo que facilita obtener una hipoteca del banco.</p>
                <p className="advice-card-text">Por otro lado, aquellos interesados en invertir en inmuebles o bienes raises sobre plano en Dubái tienen una mayor variedad de unidades para elegir dentro de una torre o comunidad, especialmente si el proyecto se acaba de lanzar. Luego, los compradores pueden bloquear unidades principales, que probablemente generarán mayores rendimientos una vez que se completen.</p>
                <h2 className="advice-card-title color">FACTORES A CONSIDERAR AL COMPRAR PROPIEDADES SOBRE PLANO VS PROPIEDADES PREPARADAS EN DUBÁI</h2>
                <p className="advice-card-text">Sin embargo, siempre aconsejo a los compradores que tengan en cuenta algunos factores para asegurarse de que están haciendo una buena inversión. Ya sea que se trate de una propiedad sobre plano o lista en Dubái, es importante realizar un análisis de mercado competitivo (CMA) verificando los precios de venta actuales de propiedades listas similares en el mercado. También es esencial verificar los precios de alquiler de propiedades similares, para tener una mejor idea de los ingresos por alquiler, que los inversores pueden generar en su propiedad.</p>
                <p className="advice-card-text">Cuando se trata de propiedades listas para la venta, hay que verificar los cargos por servicio en Dubái para esa torre o comunidad en particular, que son cargos recurrentes que deben pagar los propietarios.</p>
                <h2 className="advice-card-title color">¿ES MÁS BARATO COMPRAR UNA PROPIEDAD SOBRE PLANO EN DUBAI?</h2>
                <p className="advice-card-text">Otra pregunta común que hacen los compradores es, si es más barato comprar propiedades sobre plano que listas. Esto dependerá de cada inmobiliaria en Dubái y de la naturaleza del mercado inmobiliario en ese momento.</p>
                <p className="advice-card-text">Durante el mercado de compradores, es posible encontrar precios competitivos y mejores ofertas; esto incluye propiedades listas y sobre plano para reventa. Sin embargo, cuando el mercado inmobiliario está en auge, los compradores pueden aprovechar las ofertas lucrativas de los desarrolladores.</p>
                <p className="advice-card-text">Cuando se trata de tarifas adicionales para comprar una propiedad, los gastos son similares para las propiedades sobre plano que para las listas. Los compradores deberán pagar la tarifa de transferencia del 4% al Departamento de Tierras de Dubái, así como una tarifa de comisión del 2%.</p>
                <p className="advice-card-text">Para propiedades listas en Dubái, la tarifa de transferencia DLD generalmente se divide entre el comprador y el vendedor. Sin embargo, algunos desarrolladores ofrecen incentivos como el pago total de la tarifa DLD del 4%, que los compradores pueden aprovechar al comprar una propiedad sobre plano en Dubái.</p>
            </div>
            <div className="man-of-the-year">
                <h2>NOMINADO A EMPRENDEDOR DEL AÑO</h2>
                <div className="cnt_moty">
                    <div className="just-moty">
                        <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725563275/diego_fvzydc.webp" alt="diego" className="moty" />
                    </div>
                    <div className="just-moty">
                        <p className="moty-text">Diego carranza <br /><i className="color">Emprendedor del año</i><br /></p>
                        <a href="https://www.instagram.com/p/C0HhnI0tn3c/" className='button-contact color size'>
                            MIRALO AQUÍ
                        </a>
                    </div>
                </div>
            </div>
            <div className='news-card'>
                <h3 className='news_card-title'>COMPRA TU PRIMER PROPIEDAD EN DUBAI <i className="color">Y AHORRA DINERO</i> CON DIEGO CARRANZA</h3>
                <p className='news_card-text'>Sé parte de las muchas personas que se suman diariamente a cambiar su vida y empezar a vivir en una de las ciudades más ricas del mundo.</p>
                <a href="https://wa.link/vzpy3i" rel="noreferrer" target="_blank" className='button-contact color'>
                    AGENDA TU REUNION AQUÍ
                </a>
            </div>
        </div>
    )
}