import React from "react";
import './contactNumbers.css'
import CountUp from 'react-countup';

export default function Contactnumber() {
    return (
        <div className="cnt_contactNumber">
            <h2 className="contactNumber-title">CONTACTA CON <i className="color">DIEGO CARRANZA</i></h2>
            <div className="cnt_social-numbers">
                <div className="number">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725579286/facebook-logo_jmtcfi.webp" alt="facebook" className="image-number" />
                    <div className="cnt_text_numbers-sub">
                        <CountUp
                            start={0}
                            end={2}
                            duration={2} // Duración de la animación en segundos
                            separator=","
                            suffix="K"  // Opcional: sufijo
                            className='counter-number-social'
                        />
                        <h3 className="subtitle-number">CONTACTOS</h3>
                    </div>
                    <a target="_blank" rel="noreferrer" href="https://facebook.com/people/Diego-Javier-Carranza/100063613425457/" className="button-numbers-text">SIGUENOS</a>
                </div>
                <div className="number">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725579261/instagram-logo_sbh5wn.webp" alt="instagram" className="image-number" />
                    <div className="cnt_text_numbers-sub">
                        <CountUp
                            start={0}
                            end={7}
                            duration={2} // Duración de la animación en segundos
                            separator=","
                            suffix="K"  // Opcional: sufijo
                            className='counter-number-social'
                        />
                        <h3 className="subtitle-number">SEGUIDORES</h3>
                    </div>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/inversionesdubaiuae/" className="button-numbers-text">SIGUENOS</a>
                </div>
                <div className="number">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725579209/tiktok-logo_btiesl.webp" alt="tiktok" className="image-number" />
                    <div className="cnt_text_numbers-sub">
                        <CountUp
                            start={0}
                            end={12}
                            duration={2} // Duración de la animación en segundos
                            separator=","
                            suffix="K"  // Opcional: sufijo
                            className='counter-number-social'
                        />
                        <h3 className="subtitle-number">LIKES</h3>
                    </div>
                    <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@inversionesdubai" className="button-numbers-text">DALE ME GUSTA</a>
                </div>
                <div className="number bnone">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725579242/youtube-logo_u0bfyv.webp" alt="youtube" className="image-number" />
                    <div className="cnt_text_numbers-sub">
                        <CountUp
                            start={0}
                            end={7}
                            duration={2} // Duración de la animación en segundos
                            separator=","
                            suffix="K"  // Opcional: sufijo
                            className='counter-number-social'
                        />
                        <h3 className="subtitle-number">SUSCRIPTORES</h3>
                    </div>
                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/c/InversionesDubai/videos" className="button-numbers-text">SUSCRIBETE</a>
                </div>
            </div>
        </div>
    )
}