import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Emigrant() {
    return (
        <div className="cnt_advice">
            <Helmet>
                <title>Emigra a Dubái y obten la residencia - Inversiones Dubai</title>
                <meta name="description" content="¿Buscas asegurar el futuro de tu familia y obtener la residencia en Dubái? Diego Carranza te asesora para obtener la golden visa y vivir en los lugares más exclusivos de Dubai" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>
            <div className="cnt_first-background">
                <p className="background-title size-title"><i className="color">NUESTRA</i> MISIÓN</p>
                <p className="background-title">Ayudamos a expertos, empresas y gente del común a globalizar su mercado en Dubái</p>
            </div>
            <div className="cnt_advice-title">
                <p className="subtitle-advice">No esperes más. Nunca fue tan facil</p>
                <h1 className="title-advice">EMIGRA A DUBÁI, UNO DE LOS PAÍSES <i className="color">MÁS RICOS DEL MUNDO</i> Y APRENDE A HACER <i className="color">DINERO SIN PAGAR</i> IMPUESTOS</h1>
            </div>
            <div className="cnt_first-background-image">
            </div>
            <div className="advice-card-information">
                <p className="advice-card-text"><strong className="color">¿Estás buscando una nueva vida en otro pais?</strong> ¿Te gustaría ir a uno de los paises más seguros, confiables y deseados por todos? Desde Inversiones Dubai, Diego Carranza te ayudará a emigrar de forma legal, evitandote dolores de cabeza y analizando tu perfil para encontrar el mejor camino</p>
                <h2 className="advice-card-title color">¿Cuales son los beneficios de emigrar a Dubai?</h2>
                <h3>Oportunidades Laborales</h3>
                <p className="advice-card-text">Dubái es un centro económico global con una economía en crecimiento. Hay una alta demanda de profesionales en sectores como la tecnología, construcción, finanzas, turismo, y comercio, entre otros.</p>
                <h3>Ingresos Libres de Impuestos</h3>
                <p className="advice-card-text">Uno de los atractivos principales es que <Link to="/inversiones-impuestos-dubai" className="links-decoration">Dubái no tiene impuesto</Link> sobre la renta para individuos, lo que significa que tu salario es libre de impuestos.</p>
                <h3>Calidad de Vida</h3>
                <p className="advice-card-text">Dubái ofrece una alta calidad de vida con infraestructuras modernas, servicios de salud de primer nivel, educación internacional, y una amplia variedad de opciones de entretenimiento y ocio.</p>
                <h3>Seguridad</h3>
                <p className="advice-card-text">Dubái es conocida por ser una de las ciudades más seguras del mundo, con tasas de criminalidad muy bajas y un entorno social estable.</p>
                <h3>Infraestructura Moderna</h3>
                <p className="advice-card-text">La ciudad cuenta con un sistema de transporte eficiente, rascacielos de clase mundial, y servicios avanzados que facilitan la vida cotidiana.</p>
                <p className="advice-card-text">No esperes más y asesorate por los especialistas más grandes de Dubai.</p>
            </div>
            <div className="man-of-the-year">
                <h2>NOMINADO A EMPRENDEDOR DEL AÑO</h2>
                <div className="cnt_moty">
                    <div className="just-moty">
                        <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725563275/diego_fvzydc.webp" alt="diego" className="moty" />
                    </div>
                    <div className="just-moty">
                        <p className="moty-text">Diego carranza <br /><i className="color">Emprendedor del año</i><br /></p>
                        <a href="https://www.instagram.com/reel/C0HhnI0tn3c/?igsh=ZjRoOXJhZDZ2c2lj" rel="noreferrer" className='button-contact color size'>
                            MIRALO AQUÍ
                        </a>
                    </div>
                </div>
            </div>
            <div className='news-card'>
                <h3 className='news_card-title'>OBTEN LA RESIDENCIA EN DUBAI O <i className="color">GOLDEN VISA</i> LO MÁS ANTES POSIBLE CON DIEGO</h3>
                <p className='news_card-text'>Sé parte de las muchas personas que se suman diariamente a cambiar su vida y empezar a vivir en una de las ciudades más ricas del mundo.</p>
                <a href="https://wa.link/vzpy3i" className='button-contact color'>
                    AGENDA TU REUNION AQUÍ
                </a>
            </div>
        </div>
    )
}