import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CountUp from 'react-countup';
import Slider from 'react-slick';
import './Home.css';

import quotation from '../../assets/svg/quotation.svg'
import quotationw from '../../assets/svg/quotation-white.svg'

const WordCarousel = ({ words, index }) => {
    const props = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        reset: true,
        reverse: false,
        config: { duration: 100 }, // Duración de la animación
    });

    return (
        <div className="carousel">
            {words.map((word, i) => (
                <animated.div
                    key={i}
                    style={{
                        ...props,
                        opacity: index === i ? 1 : 0,
                    }}
                    className="word-props"
                >
                    {word}
                </animated.div>
            ))}
        </div>
    );
};

export default function Home() {

    const words = ['ASESORÍAS', 'COACHINGS', 'ESPECIALISTAS', 'PUBLICIDAD'];

    const [index, setIndex] = useState(0);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // Función para comprobar si la pantalla es de un tamaño de dispositivo móvil
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768); // Considera móvil si el ancho es <= 768px
        };

        // Comprueba cuando se carga el componente
        checkIsMobile();

        // Comprueba también si la ventana se redimensiona
        window.addEventListener('resize', checkIsMobile);

        // Limpia el listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    // Actualizar el índice de la palabra actual cada 3 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prev) => (prev + 1) % words.length);
        }, 1800); // Cambia cada 3 segundos

        return () => clearInterval(interval);
    }, [words.length]);


    const data = [
        {
            image: 'https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/7-Jesus-Rico_mjy8bv.webp',
            title: '$16.7M EN VENTAS',
            text: 'Abr 14, 2024',
            link: 'https://www.youtube.com/watch?v=5rUwN-frOTI&list=PLkY-1Giup84EJ9eRYazIkMFrNJG4Er7N8&index=7'
        },
        {
            image: 'https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/6-Andres-Crypto_u2sxlj.webp',
            title: 'RESTAURANTERO A CONSULTOR',
            text: 'Abr 1, 2024',
            link: 'https://www.youtube.com/watch?v=gxS5cGgXf48&t=6s'
        },
        {
            image: 'https://res.cloudinary.com/dm9pjru06/image/upload/v1725560823/inversiones-dubai/3_-_Damian_Ruiz_yjizjb.webp',
            title: 'EL INTERNET ME SALVÓ LA VIDA',
            text: 'Mar 9, 2024',
            link: 'https://www.youtube.com/watch?v=V1BrnnP4Fow&list=PLkY-1Giup84EJ9eRYazIkMFrNJG4Er7N8&index=3'
        },
        {
            image: 'https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/9-Christian-airbnb_hsser9.webp',
            title: 'EL NEGOCIO DEL FUTURO',
            text: 'Jun 7, 2024',
            link: 'https://www.youtube.com/watch?v=lkDcT6adnDk'
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true
    };

    return (
        <main>
            <Helmet>
                <title>Invierte en Dubái - Inversiones Dubai</title>
                <meta name="description" content="¿Buscas invertir en Dubai pero no sabes como? Diego Carranza, Asesor comercial para quienes quieren invertir en Dubai te ayuda con todo el proceso" />
            </Helmet>
            <section className="cnt_welcome-background">
                {!isMobile && (
                    <video loading="lazy" autoPlay muted loop className="background-video">
                        <source src="https://res.cloudinary.com/dm9pjru06/video/upload/v1725565515/Video_Negro_2_uvq0bl.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}

                <p className="welcome-init-title">
                    Ayudamos a <strong className="focus">expertos, empresas y gente del común a globalizar su mercado en Dubái</strong>
                </p>
                <h1 className="welcome-title">
                    INVIERTE EN DUBÁI <i>CON <span className='color'>DIEGO</span></i>
                </h1>
                <div className="carousel-container">
                    <p className="fixed-text">OBTÉN RESULTADOS REALES <span className='weight color'>POR MEDIO DE</span></p>
                    <WordCarousel words={words} index={index} />
                </div>
                <p className="additional-info">
                    <strong>¡No esperes a que tu dinero se siga devaluando por la inflación! </strong>
                    Agenda tu cita <u className='color weight'>GRATIS</u> y te ayudaremos a invertir de forma segura en Dubái <i className='color'>SIN PAGAR IMPUESTOS</i>
                </p>
                <div className='cnt_arrows'>
                    <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725561861/inversiones-dubai/Arrows-Type-1_h2p6jh.webp" alt='' className='yellow-arrows' />
                    <a href='https://wa.link/vzpy3i' rel="noreferrer" target="_blank" className='button-contact'>
                        AGENDA AQUÍ TU REUNIÓN
                    </a>
                    <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725561861/inversiones-dubai/Arrows-Type-1_h2p6jh.webp" alt='' className='yellow-arrows scarrow' />
                </div>
                <div>
                    <p className='ft'>Featured In</p>
                    <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725567799/logos_valpo7.webp" alt='logos' className='logos' />
                </div>
            </section>
            <section className='cnt_counter'>
                <div className='counter'>
                    <CountUp
                        start={0}
                        end={1500}
                        duration={2} // Duración de la animación en segundos
                        separator=","
                        suffix="+"  // Opcional: sufijo
                        className='counter-number'
                    />
                    <p className='counter-text'>ASESORADOS</p>
                </div>
                <div className='counter'>
                    <CountUp
                        start={0}
                        end={35}
                        duration={2} // Duración de la animación en segundos
                        separator=","
                        suffix="+"  // Opcional: sufijo
                        className='counter-number'
                    />
                    <p className='counter-text'>PAÍSES</p>
                </div>
                <div className='counter'>
                    <CountUp
                        start={0}
                        end={25}
                        duration={2} // Duración de la animación en segundos
                        separator=","
                        prefix="$"
                        suffix="M+"
                        className='counter-number'
                    />
                    <p className='counter-text'>GENERADO</p>
                </div>
                <div className='counter'>
                    <CountUp
                        start={0}
                        end={100}
                        duration={2} // Duración de la animación en segundos
                        separator=","
                        suffix="M+"  // Opcional: sufijo
                        className='counter-number'
                    />
                    <p className='counter-text'>VISITAS</p>
                </div>
                <p className='counter-text-info'>Desde crear, hasta lanzar tu empresa al éxito financiero. Brindamos el mejor asesoramiento del mercado, ofreciendo datos, expectativas reales y todo lo necesario para que generes la confianza suficiente en los clientes...</p>
            </section>
            <section className='cnt_news'>
                <h2 className='news-title'>INVIERTE EN <i className='color'>DUBAI</i></h2>
                <div className='cnt_news-cards'>
                    <div>
                        <div className='news-card background-option' loading="lazy">
                            <h3 className='news_card-title'>INVIERTE EN EL SECTOR INMOBILIARIO. TU CAPITAL CRECE DE FORMA SEGURA, RÁPIDA Y SIN RIESGOS</h3>
                            <p className='news_card-text'><strong>APARTAMENTOS Y SUITES EN DUBÁI</strong> es en lo que debes invertir si no tienes un perfil de riesgo alto. Diego Carranza te explica cómo invertir en el mercado inmobiliario de Dubái de forma segura.</p>
                            <Link to="/invertir-bienes-raises-dubai" className='button-contact color'>
                                MIRA CÓMO AQUÍ
                            </Link>
                        </div>
                        <div className='news-card background-option-4' loading="lazy">
                            <h3 className='news_card-title'>EMIGRA A DUBAI DE FORMA SEGURA, RÁPIDA Y A UN SOLO CLIC</h3>
                            <p className='news_card-text'>Muchas personas tienen miedo a invertir fuera de sus países por desconocimiento, <strong>pero nunca fue tan fácil como ahora.</strong> Diego Carranza te asesorará de la manera más profesional para que tengas una vista previa de todos los factores.</p>
                            <Link to="/emigrar-a-dubai" className='button-contact color'>
                                MIRA CÓMO AQUÍ
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='news-card background-option-2' loading="lazy">
                            <h3 className='news_card-title'>¿QUIERES INVERTIR EN CRIPTOMONEDAS Y EN EL MERCADO DE DUBÁI?</h3>
                            <p className='news_card-text'>Existen miles de formas de <i className='color'>incrementar tus ingresos</i> sin arreisgarlos. Diego Carranza te asesora para invertir en criptomonedas y guardar tu dinero en Dubái.</p>
                            <Link to="/invertir-criptomonedas-dubai" className='button-contact color'>
                                MIRA CÓMO AQUÍ
                            </Link>
                        </div>
                        <div className='news-card background-option-3' loading="lazy">
                            <h3 className='news_card-title'>OBTÉN LA RESIDENCIA EN DUBÁI O LA GOLDEN VISA LO MÁS ANTES POSIBLE CON DIEGO</h3>
                            <p className='news_card-text'>Si buscas residir en los Emiratos Árabes Unidos (EAU), Diego Carranza puede ayudarte con el papeleo y asesorarte para obtener de forma eficiente la residencia junto a tu familia o acompañante.</p>
                            <Link to="/obtener-residencia-dubai" className='button-contact color'>
                                MIRA CÓMO AQUÍ
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className='cnt_podcast'>
                <h2 className='podcast-title'>MIRA EL <i className='color'>PODCAST</i> EN NUESTRAS <i className='color'>REDES SOCIALES</i></h2>
                <div className='cnt_person'>
                    <div className='quotation'>
                        <img loading='lazy' src={quotation} alt='quotation' className='svg-icon-phone' />
                    </div>
                    <div className='person'>
                        <p className='person-opinion'>"Al principio tenia mis dudas con el proceso, debido a que era mucho el dinero que queria invertir, no estaba muy seguro de lo que podria pasar pero Diego me dió la confianza y la seguridad para invertir. Desde el primer momento me brindo expectativas reales y asesoramiento profesional. Aprendí muchisimo de este crack."</p>
                        <p className='person-name'> - Alejandro Fernandez</p>
                        <p className='person-work'><i>Experto en contaduría estatal</i></p>
                    </div>
                </div>
                <div className="carousel-container2">
                    <Slider {...settings}>
                        {data.map((item, index) => (
                            <div key={index} className={`card`}>
                                <img loading="lazy" src={item.image} alt={item.title} className="card-image" />
                                <div className='cnt_card-text'>
                                    <h3 className="card-title">{item.title}</h3>
                                    <p className="card-text">{item.text}</p>
                                    <a href={item.link} className='button-red'>MÍRALO AQUÍ</a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <a href='https://www.youtube.com/@InversionesDubai' className='button-youtube'>VER MÁS VIDEOS</a>
            </section>
            <section className='cnt_success-people'>
                <h2 className='title-success-people'>¿QUÉ TIENEN POR DECIR <i className='color'>MILLONARIOS</i>, <i className='color'>EXPERTOS</i>, <i className='color'>ENTRENADORES</i> E <i className='color'>INFLUENCERS TOP</i> DE LA ASESORIA DE DIEGO?</h2>
                <div className='cnt_cards-opinions'>
                    <div className='card-opinion'>
                        <img loading='lazy' src={quotationw} alt='quotation' className='quotation-opinion' />
                        <h3 className='title-opinion'>¡FACTURÉ 500K EN MI PRIMER AÑO DE EMPRESA!</h3>
                        <p className='text-opinion'>"Llegué al sitio web de Diego con la esperanza de poder cambiar mi forma de ganar dinero, buscando algo más estable, seguro y fijo.</p>
                        <p className='text-opinion'>Me guió y orientó a invertir en el mercado de Dubái, comenzando desde las criptomonedas hasta montar una sede de mi negocio.</p>
                        <p className='text-opinion'>Ha sido espectacular el alcance que he tenido gracias a la ayuda que me ha brindado."</p>
                        <p> - Lautaro Hernandez</p>
                    </div>
                    <div className='card-opinion'>
                        <img loading='lazy' src={quotationw} alt='quotation' className='quotation-opinion' />
                        <h3 className='title-opinion'>¡PASE DE OBTENER GANANCIAS 4.5K A 45K MENSUALES!</h3>
                        <p className='text-opinion'>"Soy Asesor comercial para empresas medianas. Contacté con Diego porque llegué a un punto de mi carrera en el cual no crecía profesionalmente. Llegué a él gracias a su pagina web y no me arrepiento.</p>
                        <p className='text-opinion'>Fue una de las mejores experiencias que he podido tener. Me asesoró tanto en imagen comercial como en personal, haciendo que empresas de Dubai se fijen en mi perfil.</p>
                        <p className='text-opinion'>Lo recomiendo completamente."</p>
                        <p> - Andres Garcia</p>
                    </div>
                    <div className='card-opinion'>
                        <img loading='lazy' src={quotationw} alt='quotation' className='quotation-opinion' />
                        <h3 className='title-opinion'>INCREIBLE LO QUE DIEGO LOGRA CON SU CONOCIMIENTO</h3>
                        <p className='text-opinion'>"Mi empresa estaba en busqueda de nuevas inversiones, analizando las posibilidades que tenía, llegamos a la conclusión de que Dubái era un país potencial.</p>
                        <p className='text-opinion'>Contacté con Diego debido a que necesitaba posicionar mi merca en ese mercado. Lo hizo perfectamente, me ha asesorado excelente. En menos de un año ya tenia clientes, ventas, ganancias. INCREIBLE!!!</p>
                        <p className='text-opinion'>Felicidades para este crack!"</p>
                        <p> - Sara Layla</p>
                    </div>
                </div>
            </section>
        </main>
    );
}
