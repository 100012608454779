import React from "react";
import './podcast.css'
import { Helmet } from 'react-helmet';

export default function Podcast() {
    return (
        <div className="cnt_podcast-component">
            <Helmet>
                <title>Podcast de Inversiones Dubai - Inversiones Dubai </title>
                <meta name="description" content="¿Buscas optimizar tu negocio al maximo y trabajar con uno de los más grandes del mercado? Diego Carranza te asesora como invertir en Dubai de forma correcta por medio de su podcast" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="cnt_background-podcast">
                <div className="background-first-cnt">
                    <h1 className="title-podcast-background"><i className="color">PODCAST</i></h1>
                </div>
                <div className="background-second-cnt">
                    <h2 className="subtitle-podcast-background">INVERSIONES DUBAI</h2>
                </div>
            </div>
            <div className="cnt_text-podcast">
                <h2 className="title-podcast">CONOCE A LOS FAMOSOS QUE MÁS <i className="color">DINERO GENERAN</i></h2>
                <p className="text-podcast">Mira la opinion de grandes inversionistas, millonarios, personas que surgieron por medio de mis consejos y como la vida ha tomado un cambio de 180 grados desde que tomaron la iniciativa de convertir su vida en otro mundo. Encontrarás muchos más videos como estos en el canal de <i className="color">YouTube de Diego Carranza</i>. </p>
                <p className="text-podcast">El mejor consejo que puedes recibir es que no esperes que sea tarde... Te aconsejamos como invertir tu dinero de forma segura en Dubai teniendo en cuenta tu perfil y tus deseos. "Siempre al alcance del Cliente"</p>
            </div>
            <div className="cnt_podcast-videos">
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560823/inversiones-dubai/12-Diana-Canas_ttt4rh.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">INTELIGENCIA ARTIFICIAL CAMBIA TODO</h3>
                        <p className="card-text">Abr 14, 2024</p>
                        <a href="https://www.youtube.com/watch?v=5rUwN-frOTI&list=PLkY-1Giup84EJ9eRYazIkMFrNJG4Er7N8&index=7" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/6-Andres-Crypto_u2sxlj.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">RESTAURANTERO A CONSULTOR</h3>
                        <p className="card-text">Abr 1, 2024</p>
                        <a href="https://www.youtube.com/watch?v=gxS5cGgXf48&t=6s" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560823/inversiones-dubai/3_-_Damian_Ruiz_yjizjb.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">EL INTERNET ME SALVÓ LA VIDA</h3>
                        <p className="card-text">Mar 9, 2024</p>
                        <a href="https://www.youtube.com/watch?v=V1BrnnP4Fow&list=PLkY-1Giup84EJ9eRYazIkMFrNJG4Er7N8&index=3" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/9-Christian-airbnb_hsser9.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">EL NEGOCIO DEL FUTURO</h3>
                        <p className="card-text">Jun 7, 2024</p>
                        <a href="https://www.youtube.com/watch?v=lkDcT6adnDk" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560823/inversiones-dubai/2_-_Jonnathan_ook2lq.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">GANE $500K GRACIAS A LA F1</h3>
                        <p className="card-text">Jul 29, 2024</p>
                        <a href="https://www.youtube.com/watch?v=MwGBv7Dlr8o" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560823/inversiones-dubai/11-Federico-Kompaf_ritcwq.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">EL ARTE ES COMPLEJO</h3>
                        <p className="card-text">Mar 18, 2024</p>
                        <a href="https://www.youtube.com/watch?v=h4B90rcGtAU" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/7-Jesus-Rico_mjy8bv.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">VENDÍ $16.7M EN 8 HORAS</h3>
                        <p className="card-text">Abr 13, 2024</p>
                        <a href="https://www.youtube.com/watch?v=48nu8bFd1Q8" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560824/inversiones-dubai/8-Guillermo-IFZA_sc7vhr.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">ZONA FRANCA EN DUBAI</h3>
                        <p className="card-text">May 27, 2024</p>
                        <a href="https://www.youtube.com/watch?v=MmuKiZGBpTY" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
                <div className="card-podcast-videos">
                    <img loading="lazy" src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725560823/inversiones-dubai/1_-_Carlos_Munoz_aff0i9.webp" alt="item" className="card-image-podcast-videos" />
                    <div className="cnt_card-text">
                        <h3 className="card-title-podcast-videos">VOY A ABRIR TRES EMPRESAS EN DUBAI</h3>
                        <p className="card-text">Abr 15, 2024</p>
                        <a href="https://www.youtube.com/watch?v=o3rheGo3tT4" className='button-red'>MIRALO AQUÍ</a>
                    </div>
                </div>
            </div>
        </div>
    )
}