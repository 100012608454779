import React from "react";
import './workteam.css'
import { Helmet } from 'react-helmet';

export default function Workteam() {
    return (
        <div className="cnt_wt">
            <Helmet>
                <title>Aprende más sobre Diego Carranza - Inversiones Dubai</title>
                <meta name="description" content="¿Quien es Diego Carranza? Inversionista, Asesor, Consultor, Mentor, entre muchas otras más cualidades. Es un Empresario que reside en Dubái y asesora empresarios y gente del comun para invertir." />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="cnt_workteam-image">
                <h1 className="workteam-text-title">ASESOR <i className="color">FINANCIERO</i> Y <i className="color">EMPRESARIAL</i> EN DUBÁI</h1>
                <p className="workteam-title">Diego Carranza</p>
            </div>
            <div className="cnt-workteam-text">
                <h2 className="workteam-subtitle center">¿ QUIEN ES <i className="color">DIEGO CARRANZA</i> ?</h2>
                <p className="workteam-text wtweight">Soy un apasionado de la evolución continua, que encontró la pasión en el mundo de las inversiones. A través de mi experiencia, he ayudado a miles de personas a invertir de manera eficiente y rentable en Dubai.</p>
                <p className="workteam-text">Estudié ingeniería eléctrica, donde aprendí los fundamentos para realizar mis propios análisis, los cuales me han permitido cazar grandes oportunidades. Siempre he estado buscando la manera de mejorar mi capacidad analítica y mi formación me ha brindado los fundamentos para ello.</p>
                <p className="workteam-text">Desde una etapa temprana, fui miembro del Rotary Club Internacional. Fue en el Rotary Club donde aprendí los valores de la servicialidad, liderazgo y ética profesional que me han guiado a lo largo de mi carrera.</p>
            </div>
            <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725562566/inversiones-dubai/collage1_bidpbk.webp" alt="collage-inversiones-dubai" className="collage" />
            <div className="cnt-workteam-text background-wt">
                <p className="workteam-text center colorwt">Muchas de las empresas con las que he tenido el placer de trabajar han confiado en mis metodos y les ha dado los resultados esperados. Generando así <i className="color wtweight">MILLONES DE DOLARES</i>{""} en el proceso.</p>
            </div>
            <div className="cnt-workteam-text">
                <h2 className="workteam-subtitle center">AMOR POR <i className="color">AYUDAR</i></h2>
                <p className="workteam-text">Desde muy pequeño he sentido que el ayudar a los demás es la clave del exito. Te enseña a como puedes salir adelante tu mismo siendo un ser de luz para otra persona.</p>
                <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725562730/inversiones-dubai/Copia_de_Dise%C3%B1o_sin_t%C3%ADtulo_2_gaiyzd.webp" alt="collage-inversiones-dubai" className="collage" />
                <p className="workteam-text">Me gusta entender la necesidad de las personas a las que llegan a mi porque, así les puedo brindar un mejor asesoramiento.</p>
                <p className="workteam-text">Y de igual forma mi gusto por las redes sociales, los videos y que la gente entienda como funciona Dubai es el camino que he tomado para llegar hasta donde estoy ahora.</p>
                <a href='https://wa.link/vzpy3i' className='button-youtube'>AGENDA TU REUNION AQUÍ</a>
            </div>
        </div>
    )
}