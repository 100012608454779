import React from "react";
import './goldenvisa.css'

import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet';

export default function Goldenvisa() {
    return (
        <div className="cnt_advice">
            <Helmet>
                <title>Obten la Residencia en Dubái o Golden Visa - Inversiones Dubai</title>
                <meta name="description" content="¿Buscas asegurar el futuro de tu familia y obtener la residencia en Dubái? Diego Carranza te asesora para obtener la golden visa y vivir en los lugares más exclusivos de Dubai" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725581722/Dise%C3%B1o_sin_t%C3%ADtulo_6_cpux9w.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>
            <div className="cnt_first-background">
                <p className="background-title size-title"><i className="color">NUESTRA</i> MISIÓN</p>
                <p className="background-title">Ayudamos a expertos, empresas y gente del común a globalizar su mercado en Dubái</p>
            </div>
            <div className="cnt_advice-title">
                <p className="subtitle-advice">No esperes más. Nunca fue tan facil</p>
                <h1 className="title-advice">OBTÉN <i className="color">LA RESIDENCIA EN DUBÁI</i> O LA TAN ANHELADA <i className="color">GOLDEN VISA</i></h1>
            </div>
            <div className="cnt_first-background-image">
            </div>
            <div className="advice-card-information">
                <h2 className="advice-card-title color">¿COMO OBTENER RESIDENCIA EN DUBAI?</h2>
                <p className="advice-card-text">La residencia en Dubai para algunos puede ser un lujo, pero hoy en día asegurar la estabilidad económica tuya y de tu familia puede ser algo complicado. Residir, trabajar y aportar a la economía puede ser muy benéfico para ti si lo que buscas es globalizarte.</p>
                <p className="advice-card-text">Por eso obtener la residencia en Dubai puede ser algo complicado, los llamados <strong>«Emiratos ID»</strong> pueden ser un camino para abrir puertas, oportunidades, facilitar tu vida y la de tu familia.</p>
                <p className="advice-card-text">Dubai cuenta con miles de <Link to="/" className="links-decoration">oportunidades de inversión</Link> y una de ellas es invertir en un futuro aquí. Puedo ayudarte a obtener la <strong>«GOLDEN VISA»</strong> o residencia en Dubai <Link to="/inversiones-impuestos-dubai" className="links-decoration">sin pagar impuestos</Link>  innecesarios y algunas veces pueden ser un límite para las personas. Pensar en tener que pagar altas tazas de ingreso, impuesto a la renta y/o impuesto por muchas otras cosas… Pero la realidad es que Dubai se conoce por ser uno de los países LIBRES DE IMPUESTOS si sabes como llegar de manera legal y legítima.</p>
                <h2 className="advice-card-title color">¿PUEDO TRAER A MI FAMILIA CONMIGO?</h2>
                <p className="advice-card-text">Muchas veces pensamos primero en nuestra familia. El verdadero beneficio de la «Golden Visa» es que puedes pedir a tu pareja actual «cónyuge» y a tus hijos sin ningún problema. Por ende, obtener la residencia en Dubai puede ser un privilegio tanto para ti como para tu familia. Dubai ofrece miles de oportunidades tanto estudiantiles como de inversiones a largo plazo.</p>
                <h2 className="advice-card-title color">¿Qué es la golden visa?</h2>
                <p className="advice-card-text">La «Golden Visa» o denominada mejormente residencia en Dubai se le conoce al permiso de residencia a largo plazo que otorga EUA (Emiratos Árabes Unidos) a científicos, estudiantes destacados, inversores, empresarios, etc. Es importante asegurarse de cumplir con todos los requisitos y procedimientos necesarios para incluir a los miembros de tu familia en tu solicitud de Golden Visa. Esto puede implicar la presentación de documentos adicionales y cumplir con ciertos criterios específicos para cada miembro de la familia. Pero nada de eso es preocupación cuando me tienes a mí como Asesor. Aseguro brindarte cada pequeño secreto, detalle y mantener siempre la visión de residir en Dubai viva.</p>
            </div>
            <div className="man-of-the-year">
                <h2>NOMINADO A EMPRENDEDOR DEL AÑO</h2>
                <div className="cnt_moty">
                    <div className="just-moty">
                        <img src="https://res.cloudinary.com/dm9pjru06/image/upload/v1725563275/diego_fvzydc.webp" alt="diego" className="moty" />
                    </div>
                    <div className="just-moty">
                        <p className="moty-text">Diego carranza <br /><i className="color">Emprendedor del año</i><br /></p>
                        <a href="https://www.instagram.com/reel/C0HhnI0tn3c/?igsh=ZjRoOXJhZDZ2c2lj" rel="noreferrer" className='button-contact color size'>
                            MIRALO AQUÍ
                        </a>
                    </div>
                </div>
            </div>
            <div className='news-card'>
                <h3 className='news_card-title'>OBTEN LA RESIDENCIA EN DUBAI O <i className="color">GOLDEN VISA</i> LO MÁS ANTES POSIBLE CON DIEGO</h3>
                <p className='news_card-text'>Sé parte de las muchas personas que se suman diariamente a cambiar su vida y empezar a vivir en una de las ciudades más ricas del mundo.</p>
                <a href="https://wa.link/vzpy3i" rel="noreferrer" target="_blank" className='button-contact color'>
                    AGENDA TU REUNION AQUÍ
                </a>
            </div>
        </div>
    )
}